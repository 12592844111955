import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useToast } from '../../../hooks/toast'
import api from '../../../services/api'
import { useTranslation } from 'react-i18next'

//Components
import Loading from '../../../components/Loading'
import Sidebar from '../../../components/Sidebar'
import ImgBackplate from '../../../components/ImgBackplate'
import { Chart } from 'react-google-charts'
import Footer from '../../../components/Footer'

//icons
import Arrow from '../../../assets/img/Arrow.svg'
import Completed from '../../../assets/icons/milestonecompleted.svg'
import Uncompleted from '../../../assets/icons/milestoneuncompleted.svg'
import CommentIcon from '../../../assets/icons/comment.svg'

import ModalTeamMembers from './utils/ModalTeamMembers'
import ModalHistoryComments from './utils/ModalHistoryComments'

import {
  Container,
  ContainerContent,
  Wrapper,
  TopBar,
  LeftSide,
  RightSide,
  ReturnContainer,
  ImgContainer,
  Plus,
  PlusMobile,
  OnScreenImg,
  ReturnContainerMobile,
  MemberButtons,
  PlusPlus,
  Graph,
  TableHead,
  TableContent,
  ContentFooter
} from './styles'

interface MatchParams {
  id: string
}

interface Team {
  teamData: {
    id: number
    image: string
    name: string
    TeamCount: number
    is_active: boolean
    UserTeams: [
      {
        user_id: number
        is_leader: boolean
        User: {
          name: string
          image: string
        }
      }
    ]
    Comments: [
      {
        text: string
        User: {
          name: string
          image: string
          updated_at: Date
        }
      }
    ]
  }
  countTeam: number
  is_leader: boolean
}

interface RatingHistory {
  id: number
  key_objective: number
  key_objective_goal: number
  goal_closing_date: number
  updated_at: any
  Key: {
    name: string
  }
  Milestones: [
    {
      position: number
      is_completed: boolean
    }
  ]
  KeyResults: [{ keys_id: number; key_value: number }]
}

interface Comments {
  user_id: number
  text: string
  created_at: any
  User: {
    name: string
    image: string
  }
}

const CustomTeamCultureHistory: React.FC<RouteComponentProps<MatchParams>> = (props) => {
  const history = useHistory()
  const { addToast } = useToast()
  const [loading, setLoading] = useState<boolean>(true)
  const [team, setTeam] = useState<Team>()
  const [chartData, setChartData] = useState<any[]>([])
  const [ratingHistory, setRatingHistory] = useState<RatingHistory[]>([])
  const [modalTeamMembersOpen, setModalTeamMembersOpen] =
    useState<boolean>(false)
  const [modalCommentsOpen, setModalCommentsOpen] = useState<boolean>(false)
  const [comments, setComments] = useState<Comments[]>([])
  const { t } = useTranslation()

  function getChartData() {
    api
      .get(`/rating/team/${props.match.params.id}/list/history`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        setChartData(res.data.chartData)

        res.data.chartData.forEach((element: any, i: number) => {
          if (i !== 0) {
            chartData[i] = element
            setChartData([...chartData])
          } else {
            chartData[0] = [
              { type: 'string', label: t('Date') },
              t('KeyName1'),
              t('KeyName2'),
              t('KeyName3'),
              t('KeyName4'),
              t('KeyName5'),
              t('KeyName6'),
              t('KeyName7'),
              t('KeyName8'),
              t('KeyName9')
            ]
            setChartData([...chartData])
          }
        })

        getRatingHistory()
        getHistoryComments()
        getTeamData()
      })
      .catch((err) => {
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  const data = chartData

  const options = {
    height: 500,
    colors: [
      '#FF3800',
      '#C51C1E',
      '#C5095A',
      '#52277F',
      '#33418F',
      '#2199D9',
      '#2C9647',
      '#FCEC0E',
      '#CE5E1A'
    ]
  }

  const optionsMobile = {
    legend: { position: 'none' },
    height: 400,
    colors: [
      '#FF3800',
      '#C51C1E',
      '#C5095A',
      '#52277F',
      '#33418F',
      '#2199D9',
      '#2C9647',
      '#FCEC0E',
      '#CE5E1A'
    ]
  }

  async function getTeamData() {
    api
      .get(`/team/list/byid/${props.match.params.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        setTeam(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function getRatingHistory() {
    api
      .get(`/rating/team/${props.match.params.id}/list/ratings`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        setRatingHistory(res.data.gotAllRatings)
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  async function getHistoryComments() {
    api
      .get(`/comments/team/${props.match.params.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@token/iteams')
        }
      })
      .then((res) => {
        setComments(res.data)
      })
      .catch((err) => {
        setLoading(false)
        if (err.response) {
          addToast({
            type: err.response.data.Toast.type,
            title: t(err.response.data.Toast.title),
            description: t(err.response.data.Toast.description)
          })
        } else {
          addToast({
            type: 'error',
            title: t('Server'),
            description: t('CommunicationError')
          })
        }
      })
  }

  useEffect(() => {
    getChartData()

    //eslint-disable-next-line
  }, [])

  return (
    <>
      {modalTeamMembersOpen && (
        <ModalTeamMembers
          setModal={setModalTeamMembersOpen}
          teamData={team?.teamData}
          getTeam={getTeamData}
          isTeamActive={team?.teamData.is_active}
        />
      )}
      {modalCommentsOpen && (
        <ModalHistoryComments
          setModal={setModalCommentsOpen}
          comments={comments}
        />
      )}

      <Container>
        <Sidebar />
        <ContainerContent>
          {loading ? (
            <Loading />
          ) : (
            <>
              <TopBar>
                <LeftSide>
                  <ReturnContainer
                    onClick={() => {
                      if (team?.teamData.is_active) {
                        history.push(`/team/details/${props.match.params.id}`)
                      } else {
                        history.push(`/team`)
                      }
                    }}
                  >
                    <ImgBackplate
                      image={Arrow}
                      width={'40px'}
                      height={'40px'}
                      rotate={'180deg'}
                    />
                    <h3>{t('Return')}</h3>
                  </ReturnContainer>

                  <h1>{team?.teamData.name}</h1>
                </LeftSide>
                <RightSide>
                  <ReturnContainerMobile
                    onClick={() => {
                      history.push(`/team/details/${props.match.params.id}`)
                    }}
                  >
                    <ImgBackplate
                      image={Arrow}
                      width={'40px'}
                      height={'40px'}
                      rotate={'180deg'}
                    />
                  </ReturnContainerMobile>

                  <MemberButtons>
                    <PlusPlus
                      onClick={() => {
                        setModalTeamMembersOpen(true)
                      }}
                    >
                      <h3>+</h3>
                    </PlusPlus>

                    <ImgContainer
                      onClick={() => {
                        setModalTeamMembersOpen(true)
                      }}
                    >
                      {team?.teamData.UserTeams.map((element, key) => {
                        return (
                          <OnScreenImg key={key}>
                            <ImgBackplate
                              image={element.User.image}
                              width="44px"
                              height="44px"
                              radius="100%"
                              cursor="pointer"
                            />
                          </OnScreenImg>
                        )
                      })}
                    </ImgContainer>

                    {team?.teamData.TeamCount! > 3 && (
                      <Plus
                        onClick={() => {
                          setModalTeamMembersOpen(true)
                        }}
                      >
                        <h3>+{team?.countTeam !== 0 && team?.countTeam}</h3>
                      </Plus>
                    )}

                    <PlusMobile
                      onClick={() => {
                        setModalTeamMembersOpen(true)
                      }}
                    >
                      <h3>{team?.teamData.TeamCount}</h3>
                    </PlusMobile>
                  </MemberButtons>
                </RightSide>
              </TopBar>
              <Wrapper>
                {chartData.length > 1 ? (
                  <>
                    <Graph>
                      <div className="web">
                        <Chart
                          chartType="Line"
                          data={data}
                          options={options}
                          width="100%"
                        />
                      </div>
                      <div className="mobile">
                        <Chart
                          chartType="Line"
                          data={data}
                          options={optionsMobile}
                          width="100%"
                        />
                      </div>
                    </Graph>
                    <ContentFooter
                      onClick={() => {
                        setModalCommentsOpen(true)
                      }}
                    >
                      <ImgBackplate
                        image={CommentIcon}
                        width="18px"
                        height="18px"
                      />
                      <h3>{t('TeamHistory1')}</h3>
                    </ContentFooter>
                  </>
                ) : (
                  <div className="nodata">
                    <h2>{t('TeamHistory2')}</h2>
                  </div>
                )}

                {ratingHistory.length > 0 && (
                  <>
                    <TableHead>
                      <h3>{t('TeamHistory3')}</h3>
                      <ul>
                        <li>
                          <h4>{t('LeverArea')}</h4>
                        </li>
                        <li>
                          <h4>{t('TeamHistory4')}</h4>
                        </li>
                        <li>
                          <h4>{t('TeamHistory5')}</h4>
                        </li>
                        <li>
                          <h4>{t('TeamDetailsMessage27')}</h4>
                        </li>
                        <li>
                          <h4>{t('TeamDetailsMessage28')}</h4>
                        </li>
                        <li>
                          <h4>{t('TeamDetailsMessage29')}</h4>
                        </li>
                        <li>
                          <h4>{t('TeamHistory6')}</h4>
                        </li>
                      </ul>
                    </TableHead>
                    {ratingHistory?.map((element, i: number) => {
                      return (
                        <TableContent
                          key={i}
                          onClick={() => {
                            history.push(
                              `/team/details/${props.match.params.id}/history/details/${element.id}`
                            )
                          }}
                        >
                          <ul>
                            <li>
                              <h4>{t(element.Key.name)}</h4>
                            </li>

                            <li>
                              {element.KeyResults.map((key) => {
                                return (
                                  key.keys_id === element.key_objective &&
                                  Number(key.key_value).toFixed(0) + '/10'
                                )
                              })}
                            </li>

                            <li>
                              <ImgBackplate
                                image={
                                  element.Milestones.filter((e) => {
                                    return e.is_completed === true
                                  }).length === 3
                                    ? Completed
                                    : Uncompleted
                                }
                                width={'24px'}
                                height={'24px'}
                              />
                              {element.key_objective_goal}/10
                            </li>

                            {element.Milestones.map((el, j: number) => {
                              return (
                                <li key={j}>
                                  <h4>
                                    <ImgBackplate
                                      image={
                                        el.is_completed
                                          ? Completed
                                          : Uncompleted
                                      }
                                      width={'24px'}
                                      height={'24px'}
                                    />
                                  </h4>
                                </li>
                              )
                            })}

                            <li>
                              <h4>
                                {new Date(
                                  element.updated_at
                                ).toLocaleDateString('pt-BR', {
                                  timeZone: 'UTC'
                                })}
                              </h4>
                            </li>
                          </ul>
                        </TableContent>
                      )
                    })}
                  </>
                )}
              </Wrapper>
            </>
          )}
          <Footer></Footer>

        </ContainerContent>
      </Container>
    </>
  )
}

export default CustomTeamCultureHistory
